import React, {useEffect, useState} from "react";
import sp from "../../../Images/Spline/daccord.spline";
import Spline from "@splinetool/react-spline";
import styled from "styled-components";
import twitter from "../../../Images/Spline/images/icon-twitter.svg";
import youtube from "../../../Images/Spline/images/icon-youtube.svg";
import laptop from "../../../Images/Spline/images/icon-laptop.svg";
import {Box} from "@mui/material";
import ReactTextTransition, {presets} from "react-text-transition";
import getRandomNumber from "../../../Utils/index"



const Wrapper = styled.div`
  //font-family: 'Spline Sans Mono', monospace;
  font-size: 16px;
  color: white;
  position: relative;
  width: 100%;
  height: 100%;
  //overflow: hidden;

  Box > Spline >  {
    position: absolute;
    margin: 0;
    top: 0;
    right: 0;

    @media (max-width: 1024px) {
      transform: scale(0.8) translateX(200px);
      transform-origin: top;
    }
    @media (max-width: 800px) {
      transform: scale(0.7) translateX(600px);
    }
    @media (max-width: 600px) {
      transform: scale(0.5) translateX(-100px);
      right: auto;
      left: 50%;
      margin-left: -600px;
    }
    @media (max-width: 375px) {
      transform: scale(0.45) translateX(-50px);
    }
  }
`

const Content = styled.div`
  //position: absolute;
  //top: 30px; 
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 1;
  //width: 100%;
  margin-top: 200px;


  @media (max-width: 1024px) {
    gap: 40px;
    width: 100%;
  }


  h1 {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 60px;
    margin: 0;
    max-width: 700px;


    @media (max-width: 1024px) {
      font-size: 68px;
      max-width: 400px;
    }
    @media (max-width: 800px) {
      font-size: 48px;
      max-width: 300px;
    }
    @media (max-width: 600px) {
      padding-top: 250px;
    }
  }

  h1, p, button {
    margin: 0 30px 0 100px;

    @media (max-width: 1024px) {
      margin: 0 30px;
    }
  }
`


const Social = styled.div`
  position: absolute;
  top: 150px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  div {
    width: 1px;
    height: 500px;
    background: linear-gradient(180deg,
    #08b6f9 0%,
    #6c56ef 33.57%,
    #1306dd 65.86%,
    #aa0eb2 100%);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`


const texts = [
    "Fast",
    "Scalable",
    "Potato",
    "Cabbage",
    "Cucumber",
    "Tomato"
];

const colors = [
    "#F1C40F",
    "#5DADE2",
    "#C39BD3",
    "#00A0ED",
    "#434FFF",
    "#CC08A1"

]

const Home = () => {
    const [textIndex, setTextIndex] = useState(0);
    const [color, setColor] = useState(0)


    useEffect(() => {
        let interval = setInterval(() => {
            setTextIndex(getRandomNumber(0, texts.length));
            setColor(getRandomNumber(0, colors.length))
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        // <Container maxWidth={'xl'}>
        <Wrapper>
            <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", alineItem: 'center'}}>
                <Content>
                    <section>
                        <h1>
                            <section className="inline">
                                Create
                                <ReactTextTransition
                                    springConfig={presets.gentle}
                                    style={{margin: "0 10px", color: colors[color]}}
                                    inline
                                >
                                    {texts[textIndex]}
                                </ReactTextTransition>
                                React Applications.
                            </section>
                        </h1>
                    </section>

                    <p>Welcome to CodeCrafters, your gateway to the world of React.js innovation! We specialize in
                        creating exceptional web experiences using React.js technology. Let's bring your ideas to life
                        and elevate your online presence together.</p>
                    <button style={{backgroundColor: `${colors[color]}50`}}><img src={laptop} alt=""/> more info</button>
                </Content>
                <Box sx={{position: "absolute", right: "-110px"}}>
                    <Spline
                        scene={sp}
                    />
                </Box>
            </Box>
            <Social>
                <div/>
                <img src={twitter} alt=""/>
                <img src={youtube} alt=""/>
            </Social>
        </Wrapper>
        // </Container>
    )
}

export default Home;