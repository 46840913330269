import React from "react";
import logo from "../../../Images/Spline/images/logo.svg";
import styled from "styled-components";
import classes from "./Topbar.module.css"


const Menu = styled.ul`
  display: flex;
  gap: 20px;
  align-items: center;
  //margin: 0 30px 0 100px;
  justify-content: space-between;
  padding: 14px 50px;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: rgb(13, 17, 43);
  //backdrop-filter: blur(100px);
  
  @media (max-width: 1024px) {
    margin: 0 30px;
  }

  li {
    list-style: none;
    margin: 0;
  }

  span {
    display: flex;
    gap: 10px;
  }

  a {
    text-decoration: none;
    color: white;
    padding: 5px 20px;
    border: 1px solid rgba(225, 225, 225, 0);
    transition: 0.5s;
    border-radius: 14px;
    font-size: 19px;
  }

  @media (max-width: 800px) {
    justify-content: space-between;
    li:nth-child(2),
    li:nth-child(3),
    li:nth-child(4),
    li:nth-child(5) {
      display: none;
    }
  }
`
const Topbar = () => {
    return (<>
        <Menu>
            <li><img src={logo} alt="logo"/></li>
            <span>
                    <li><a href="#home" className={classes.menuBtn}>Home</a></li>
                    <li><a href="#about" className={classes.menuBtn}>About</a></li>
                    <li><a href="#service" className={classes.menuBtn}>Services</a></li>
                    <li><a href="#home" className={classes.menuBtn}>Blogs</a></li>
                    <li><a href="#home" className={classes.menuBtn}>Contact Us</a></li>
                </span>
        </Menu>
    </>)
}

export default Topbar;