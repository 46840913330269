import './App.css';
import {Home} from "./Components/Pages/Home";
import Topbar from "./Components/Layout/Topbar/Topbar";
import React, {useEffect, useState} from "react";
import About from "./Components/Pages/About/About";
import Service from "./Components/Pages/Service/Service";
import sp from "./Images/portal_copy.spline";
import Spline from "@splinetool/react-spline";


function App() {

    const [view, setView] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setView(true)
        }, 3000);
    }, [])

    return (
        <div className="App">
            {view ?
                <>
                    <Topbar/>
                    <div id={"home"}>
                        <Home/>
                    </div>
                    <div id={"about"}>
                        <About/>
                    </div>
                    <div id={"service"}>
                        <Service/>
                    </div>
                </> :
                <Spline
                scene={sp}
        />
            }
        </div>
    );
}

export default App;
