import React from "react";
import {Box, Button, Container, Grid} from "@mui/material";
import classes from "./About.module.css"
import clsx from "clsx";
import ReadMoreIcon from '@mui/icons-material/ReadMore';

const About = () => {
    return (<Box className={classes.mainColor}>
            <Container>
                <Grid container spacing={6} alignItems={"center"}>
                    <Grid item xs={7}>
                        <Grid container spacing={4} alignItems={"center"}>
                            <Grid item xs={6}>
                                <Box className={classes.firstBox}>
                                    <Box className={classes.cardBottom}>
                                        <Box className={classes.cardFlex}>
                                            <Box className={classes.leftFlex}>
                                                <span className={classes.cardHeading}>LAC SUPERIEUR</span>
                                                <span>30 mins ago</span>
                                                <span>3,054 STEPS</span>
                                            </Box>
                                            <Box className={classes.leftFlex} s>
                                                <Button>VISIT</Button>
                                                <span>3% HIGHER</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={clsx(classes.secoundPosi)}>
                                    <Box>
                                        <span>Close Your Rings</span>
                                        <span>Close your Move ring by hitting your personal goal of active calories burned.</span>
                                    </Box>
                                    <Box className={classes.cardBottom}>
                                        <Box className={classes.cardFlex}>
                                            <Box className={classes.leftFlex}>
                                                <span className={classes.cardHeading}>LAC SUPERIEUR</span>
                                                <span>30 mins ago</span>
                                                <span>3,054 STEPS</span>
                                            </Box>
                                            <Box className={classes.leftFlex} s>
                                                <Button>VISIT</Button>
                                                <span>3% HIGHER</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box className={clsx(classes.firstBox, classes.thirdBox)}>
                                    <Box className={classes.cardBottom}>
                                        <Box className={classes.cardFlex}>
                                            <Box className={classes.leftFlex}>
                                                <span className={classes.cardHeading}>LAC SUPERIEUR</span>
                                                <span>30 mins ago</span>
                                                <span>3,054 STEPS</span>
                                            </Box>
                                            <Box className={classes.leftFlex} s>
                                                <Button>VISIT</Button>
                                                <span>3% HIGHER</span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={4} alignItems={"center"}>
                                    <Grid item xs={12}>
                                        <Box className={clsx(classes.firstBox, classes.fourBox)}>
                                            <Box className={classes.cardBottom}>
                                                <Box className={classes.cardFlex}>
                                                    <Box className={classes.leftFlex}>
                                                        <span className={classes.cardHeading}>LAC SUPERIEUR</span>
                                                        <span>30 mins ago</span>
                                                        <span>3,054 STEPS</span>
                                                    </Box>
                                                    <Box className={classes.leftFlex} s>
                                                        <Button>VISIT</Button>
                                                        <span>3% HIGHER</span>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className={clsx(classes.firstBox, classes.fifthBox)}>
                                            <Box className={classes.cardBottom}>
                                                <Box className={classes.cardFlex}>
                                                    <Box className={classes.leftFlex}>
                                                        <span className={classes.cardHeading}>LAC SUPERIEUR</span>
                                                        <span>30 mins ago</span>
                                                        <span>3,054 STEPS</span>
                                                    </Box>
                                                    <Box className={classes.leftFlex} s>
                                                        <Button>VISIT</Button>
                                                        <span>3% HIGHER</span>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/*........right side.........*/}
                    <Grid item xs={5}>
                        <Box className={classes.rightContent}>
                            <Box className={classes.svg}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false"
                                     color="rgb(238, 68, 68)"
                                     style={{
                                         userSelect: "none",
                                         width: "70px",
                                         height: "100%",
                                         display: "inline-block",
                                         fill: "rgb(238, 68, 68)",
                                         flexShrink: "0",
                                         cursor: "auto"
                                     }}>
                                    <g color="rgb(238, 68, 68)" weight="duotone">
                                        <path
                                            d="M73.87573,111.39355l-.009-.03369L42.51636,148.98047a7.99958,7.99958,0,0,0-1.66358,6.85693l12.3628,55.63281a8,8,0,0,0,12.80713,4.51172L96,192C78.36523,161.44922,72.74268,134.47656,73.87573,111.39355Z"
                                            opacity="0.2"></path>
                                        <path
                                            d="M181.52239,110.6714l.009-.03369,31.35034,37.6206a7.99958,7.99958,0,0,1,1.66358,6.85693l-12.3628,55.63282a8,8,0,0,1-12.80712,4.51172l-29.9773-23.98194C177.03289,160.72706,182.65544,133.75441,181.52239,110.6714Z"
                                            opacity="0.2"></path>
                                        <line x1="144" y1="224" x2="112" y2="224" fill="none" stroke="rgb(238, 68, 68)"
                                              stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                        <path
                                            d="M122.97053,19.76275C103.97814,34.97686,40.45429,95.77187,96,192h64c54.39078-96.16-8.20388-156.9371-26.9971-172.20489A7.98387,7.98387,0,0,0,122.97053,19.76275Z"
                                            fill="none" stroke="rgb(238, 68, 68)" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="16"></path>
                                        <path
                                            d="M73.86661,111.36007,42.51644,148.98028a8,8,0,0,0-1.66373,6.85692l12.36292,55.63314a8,8,0,0,0,12.80706,4.51151L96,192"
                                            fill="none" stroke="rgb(238, 68, 68)" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="16"></path>
                                        <path
                                            d="M181.53142,110.63771l31.95214,38.34257a8,8,0,0,1,1.66373,6.85692l-12.36292,55.63314a8,8,0,0,1-12.80706,4.51151L160,192"
                                            fill="none" stroke="rgb(238, 68, 68)" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="16"></path>
                                        <circle cx="128" cy="96" r="12"></circle>
                                    </g>
                                </svg>
                            </Box>
                            <p className={classes.mainTitle}>We're Codecrafter, a React development powerhouse!</p>
                            <Box>
                                <p className={classes.subtext}>At Codecrafter, we are passionate React developers with a flair for crafting captivating digital experiences. As a React Jedi, I founded this company with a vision to bring cutting-edge technology and design together to create exceptional web solutions.</p>
                                <Button className={classes.learnBtn}>
                                    <ReadMoreIcon style={{color: "white"}}/>
                                    LEARN MORE
                                </Button>
                            </Box>
                            <span>Grow your business, reach new audiences, and hit your goals with integrations.</span>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default About;