import React from "react";
import {Box, Container} from "@mui/material";
import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import classes from "./Service.module.css"

const Service = () => {
    return (
        <Box>
            <Container>
                <Box className={classes.headersection}>
                    <Box>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" focusable="false"
                             color="rgb(42, 25, 255)"
                             style={{userSelect: "none", width: "70px", height: "100%", display: "inlineBlock", fill:"rgb(42, 25, 255)", flexShrink: "0", cursor: "auto"}}>
                            <g color="rgb(42, 25, 255)" weight="duotone">
                                <path
                                    d="M56,56v55.09613c0,39.70052,31.75464,72.60753,71.45407,72.90185A72.00008,72.00008,0,0,0,200,112V56a8,8,0,0,0-8-8H64A8,8,0,0,0,56,56Z"
                                    opacity="0.2"></path>
                                <path
                                    d="M56,56v55.09613c0,39.70052,31.75464,72.60753,71.45407,72.90185A72.00008,72.00008,0,0,0,200,112V56a8,8,0,0,0-8-8H64A8,8,0,0,0,56,56Z"
                                    fill="none" stroke="rgb(42, 25, 255)" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="16"></path>
                                <line x1="96" y1="224" x2="160" y2="224" fill="none" stroke="rgb(42, 25, 255)"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                <line x1="128" y1="184" x2="128" y2="224" fill="none" stroke="rgb(42, 25, 255)"
                                      stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></line>
                                <path d="M198.21561,128H208a32,32,0,0,0,32-32V80a8,8,0,0,0-8-8H200" fill="none"
                                      stroke="rgb(42, 25, 255)" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="16"></path>
                                <path d="M57.964,128H47.87289a32,32,0,0,1-32-32V80a8,8,0,0,1,8-8h32" fill="none"
                                      stroke="rgb(42, 25, 255)" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="16"></path>
                            </g>
                        </svg>
                    </Box>
                    <p className={classes.mainTitle}>Text styles, variants and overlays</p>
                    {/*<p>Build a website without writing a single line of code using Framer components and customizing the*/}
                    {/*    entire content</p>*/}
                </Box>
                <Box className={classes.timelineBox}>
                    <VerticalTimeline>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{
                                backgroundColor: 'rgb(207, 233, 255)',
                                color: '#0d112b',
                                border: "3px solid rgb(180, 223, 255)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid rgb(207, 233, 255)'}}
                            date="2011 - present"
                            iconStyle={{background: 'rgb(180, 223, 255)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Creative Director</h3>
                            <h4 className="vertical-timeline-element-subtitle">Miami, FL</h4>
                            <p>
                                Creative Direction, User Experience, Visual Design, Project Management, Team Leading
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{
                                background: 'rgb(229, 220, 255)',
                                color: '#0d112b',
                                border: "3px solid rgb(210, 194, 255)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(229, 220, 255)',}}
                            iconStyle={{background: 'rgb(210, 194, 255)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Art Director</h3>
                            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                            <p>
                                Creative Direction, User Experience, Visual Design, SEO, Online Marketing
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{
                                background: 'rgb(255, 210, 229)',
                                color: '#0d112b',
                                border: "3px solid rgb(255, 190, 218)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(255, 210, 229)'}}
                            iconStyle={{background: 'rgb(255, 190, 218)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Web Designer</h3>
                            <h4 className="vertical-timeline-element-subtitle">Los Angeles, CA</h4>
                            <p>
                                User Experience, Visual Design
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{
                                background: 'rgb(255, 228, 194)',
                                color: '#0d112b',
                                border: "3px solid rgb(252, 208, 154)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(255, 228, 194)'}}
                            iconStyle={{background: 'rgb(252, 208, 154)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Web Designer</h3>
                            <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
                            <p>
                                User Experience, Visual Design
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{
                                background: 'rgb(186, 233, 228)',
                                color: '#0d112b',
                                border: "3px solid rgb(173, 218, 213)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(186, 233, 228)'}}
                            iconStyle={{background: 'rgb(173, 218, 213)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social
                                Media</h3>
                            <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
                            <p>
                                Strategy, Social Media
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{
                                background: 'rgb(227, 237, 180)',
                                color: '#0d112b',
                                border: "3px solid rgb(214, 227, 149)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(227, 237, 180)'}}
                            iconStyle={{background: 'rgb(214, 227, 149)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
                            <h4 className="vertical-timeline-element-subtitle">Certification</h4>
                            <p>
                                Creative Direction, User Experience, Visual Design
                            </p>
                        </VerticalTimelineElement>
                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{
                                background: 'rgb(255, 215, 253)',
                                color: '#0d112b',
                                border: "3px solid rgb(242, 192, 240)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(255, 215, 253)'}}
                            iconStyle={{background: 'rgb(242, 192, 240)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital
                                Media Visual Imaging</h3>
                            <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                            <p>
                                Creative Direction, Visual Design
                            </p>
                        </VerticalTimelineElement>

                        <VerticalTimelineElement
                            className="vertical-timeline-element--education"
                            contentStyle={{
                                background: 'rgb(255, 214, 201)',
                                color: '#0d112b',
                                border: "3px solid rgb(241, 195, 181)"
                            }}
                            contentArrowStyle={{borderRight: '7px solid  rgb(255, 214, 201)'}}
                            iconStyle={{background: 'rgb(241, 195, 181)', color: '#0d112b'}}
                            icon={<AcUnitIcon/>}
                        >
                            <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital
                                Media Visual Imaging</h3>
                            <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
                            <p>
                                Creative Direction, Visual Design
                            </p>
                        </VerticalTimelineElement>
                    </VerticalTimeline>
                </Box>
            </Container>
        </Box>
    )
}

export default Service;